<template>
    <q-dialog 
      ref="dialogVEx"
      v-model="modalForm.show"
      content-class="cannaFormModal"
      transition-show="scale" 
      transition-hide="scale"
      :persistent="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-width="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-height="(is_Mobile|| $q.screen.lt.sm)?true:false"
      :no-esc-dismiss="(is_Mobile|| $q.screen.lt.sm)?false: ((modalForm.processing) ? true : false)"
      :no-backdrop-dismiss="(is_Mobile|| $q.screen.lt.sm)?false:true"
      @show="setShownDialog"
      @escape-key="doCloseDialog(true)"
    >
      <q-card class="canna_Formx ">
        <!-- encabezado -->
        <q-card-section horizontal class="q-card q-pa-md flex justify-between column noselect">
          <div class="closeButton">
            <cxIcon
              iconType="ui_close"
              size="24px"
              class=""
              :cls="'icon_close_svg'"
              :state="(modalForm.processing === false) ? 0 : 2"
              :hasMouseOver="true"
              :hasAction="true"
              :allowDisabled="true"
              :allowClick="true"
              @iconClick="doCloseDialog"
            />
          </div>
          <div class="form_title">

            <template v-if="actionForm === 'remove'">

              <span>Eliminar correo electrónico</span>
              <div class="subtitle">
                Esta acción no podrá deshacerse.
              </div>
            </template>
            <template v-else-if="actionForm === 'mainchange'">

              <span>Cambiar correo electrónico principal</span>

            </template>
            <template v-else><!-- // verify, add -->

              <span>Verificar mi dirección de <template v-if=" $q.screen.gt.sm"><br></template> correo electrónico</span>
              
              <template v-if="modalForm.formErrors>0">
                <div class="subtitleAlt subtitleError">
                  Hay elementos que necesitan tu atención
                </div>
              </template>

            </template>

          </div>
        </q-card-section>

        <q-separator />
        <!-- formulario -->
        <template v-if="actionForm === 'verify' || actionForm === 'add'">

          <q-card-section  style="max-height: 85vh" class="canna_FormxContents scroll">
            <!-- Contenido del formulario -->

            <!-- PASO 1 -->
            <div v-show="(modalForm.stepForm===0)">

              <div class="subtitleAlt noselect">
                <div>
                  Da clic en el siguiente botón para comenzar el proceso de verificación de tu dirección de correo electrónico.
                </div>
                <div class="spacerRowTop24">
                  Enviaremos un correo electrónico que permitirá realizar la validación de la cuenta 
                  <span style="font-weight: 500;">{{ (emailRecord!==null) ? emailRecord.email : '' }}</span>
                </div>
              </div>

              <ValidationObserver ref="formVEmail" v-slot="{ handleSubmit }">
                <q-form
                  ref="myformVEmail"
                  @submit.prevent="handleSubmit(onSubmit)"
                  @reset="onReset"
                  class="canna_forma_zona"
                  style=""
                > 

                  <template v-if="(frmMainErrors.formVEmail !== '')">
                    <div class="canna_frmMainErrorZone spacerRowTop28" style="display: flex;flex-direction: row;align-items: center;">
                      <div style="margin-right:10px;">
                        <img src="images/login_warning.png"/>
                      </div>
                      <div style="flex:1;" v-html="frmMainErrors.formVEmail"></div>
                    </div>
                  </template>

                  <div class="buttonZone">
                    <q-btn
                      unelevated
                      :label="'Enviar correo de validación'"
                      type="submit" class="buttonSave_SE" color="app_mainBtn" :disabled="modalForm.processing" 
                    />
                  </div>

                  <div class="noselect subtitleAlt spacerRowTop24" style="text-align: center;">
                    <div class="spacerRowTop4 action_item actionLabel actionLabelAlt" @click.stop="do_ChangeToEmailForm">
                      Cambiar dirección de correo electrónico
                    </div>
                  </div>

                </q-form>
              </ValidationObserver>

            </div>

            <!-- PASO 2 -->
            <!-- Zona de notificacion -->
            <template v-if="(modalForm.stepForm===1 && alertActionShow)">
              <div class="notifyZone noselect"
                :class="(alertActionType!==0) ? 'notifyZoneRemove' : ''"
                :style="(is_Mobile|| $q.screen.lt.sm) ? 'max-height: 59px; height: auto; padding: 8px 8px;' : '' "
              >
                <div style="flex: 1 1 0%; justify-content: center;align-items: center;">
                    <q-icon name="check_circle" style="color:#ffffff; font-size: 22px; margin-right: 10px;" />
                    {{ alertActionMsg }}
                </div>
                <div style="margin-right: 14px;">
                    <div class="closenotifyZoneIcon action_item" @click.stop=" alertActionShow = false ">
                      <q-icon name="clear" style="color:#ffffff; font-size: 20px;" />
                    </div>
                </div>
              </div>
            </template>

            <div v-show="(modalForm.stepForm===1)">

              <div class="subtitleAlt noselect">
                <div>
                  ¡Listo! Se ha enviado un correo electrónico a 
                  <span style="font-weight: 500;">
                    {{ frmData.email }}
                  </span>
                </div>
                <div class="spacerRowTop24">
                  Sigue las instrucciones que se describen en este correo para completar el proceso de verificación.
                </div>
              </div>

              <ValidationObserver ref="formVEmailFinal" v-slot="{ handleSubmit }">
                <q-form
                  ref="myformVEmailFinal"
                  @submit.prevent="handleSubmit(onSubmitClose)"
                  @reset="onReset"
                  class="canna_forma_zona "
                  style=""
                > 
                  <div class="row spacerRowTop24 q-gutter-md justify-center">

                  </div>

                  <div class="buttonZone" style="margin-top: 28px !important;">
                    <q-btn 
                      type="button" class="buttonSave_SE" color="app_mainBtn"
                      unelevated 
                      :label="'Cerrar'"  
                      :disabled="modalForm.processing" 
                      @click.stop="doCloseFinishDialog"
                    />
                  </div>

                  <div class="noselect subtitleAlt spacerRowTop32" style="text-align: center;">
                    <div>
                      ¿No has recibido el correo electrónico de verificación? <template v-if=" $q.screen.gt.sm"><br></template>
                      Por favor asegúrate de revisar tu carpeta de correo no deseado, si aún no lo encuentras prueba con
                    </div>

                    <template v-if="pomodoro.canSendLink===false">
                      <div class="spacerRowTop4">
                        Podrás solicitar un nuevo correo en {{pomodoro.timerCount}} segundos.
                      </div>
                    </template>
                    <template v-if="pomodoro.canSendLink===true">
                      <div class="spacerRowTop8 action_item actionLabel actionLabelAlt" @click.stop="onSubmit(true)">
                        Enviar otro correo electrónico de verificación
                      </div>
                    </template>
                    <template v-if="pomodoro.canSendLink===true">
                      <div class="spacerRowTop32 action_item actionLabel actionLabelAlt" @click.stop="do_ChangeToEmailForm">
                        Cambiar dirección de correo electrónico
                      </div>
                    </template>

                  </div>

                </q-form>
              </ValidationObserver>

            </div>

            <!-- PASO 3 cambiar email -->
            <div v-show="(modalForm.stepForm===2)">

              <div class="subtitleAlt noselect">
                <div>
                  A continuación, introduce tu nueva dirección de correo electrónico y después da clic en el botón para comenzar el proceso de verificación.
                </div>
              </div>

              <ValidationObserver ref="formVChangeEmail" v-slot="{ handleSubmit, valid, passed  }">
                <q-form
                  ref="myEmailChangeForm"
                  @submit.prevent="handleSubmit(onSubmitChangeEmail)"
                  @reset="onResetChangeEmail"
                  class="canna_forma_zona "
                  style=""
                >

                  <!-- Campo de email -->
                  <ValidationProvider 
                    ref="fldx_changeMail" name="emailx" rules="required|email|emojiFree|max:255" :immediate="true" v-slot="{ errors, invalid, validated  }"
                  >
                    <div class="canna_forma_campo_container spacerRowTop24">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{'fieldError':invalid && validated}"
                        >Correo electrónico</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        name="emailx"
                        type="email"
                        v-model.trim="frmDataChange.email"
                        hint=""
                        :dense="true"
                        :label="(frmDataChange.email==='')?'ejemplo@email.com':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ? errores.email : ''"
                        :disabled="processingData"
                        @input="val => { frmDataChange.email = ('' + val).trim()} "
                      >
                      <!--
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('Email','Correo electrónico').replace('El campo','El') : ''"
                      -->
                        <template v-slot:append>
                          <q-icon v-if="frmDataChange.email !== null" class="cursor-pointer" name="clear" @click.stop="frmDataChange.email = null"/>
                        </template>
                      </q-input>
                    </div>
                  </ValidationProvider>

                  <template v-if="(frmMainErrors.formVChangeEmail !== '')">
                    <div class="canna_frmMainErrorZone spacerRowTop28" style="display: flex;flex-direction: row;align-items: center;">
                      <div style="margin-right:10px;">
                        <img src="images/login_warning.png"/>
                      </div>
                      <div style="flex:1;" v-html="frmMainErrors.formVChangeEmail"></div>
                    </div>
                  </template>

                  <div class="buttonZone" style="margin-top: 32px !important;">
                    <q-btn 
                      type="submit" class="buttonSave_SE" color="app_mainBtn" unelevated :label="'Enviar correo de validación'" 
                      :disable="((passed || valid) )? false : true"
                    />
                  </div>

                </q-form>
              </ValidationObserver>

            </div>

          </q-card-section>

        </template>


        <!-- No se usan por el momento -->
        <template v-if="actionForm === 'remove'">

          <q-card-section  style="max-height: 85vh" class="canna_FormxContents scroll">
            <div class="subtitleAlt noselect">
              <div>
                Estás seguro de eliminar la dirección de correo electrónico <span style="font-weight: 500;">remigio.garcia@hotmail.com</span>
              </div>
            </div>

            <div class="buttonZone" style="margin-top: 80px !important;">
              <q-btn unelevated outline  :label="'Cancelar'" type="button" class="buttonSave_SE" color="app_mainBtn" :disabled="modalForm.processing" />

              <q-btn unelevated :label="'Eliminar correo electrónico'" type="button" class="buttonSave_SE spacerRowTop24" color="app_mainBtn" :disabled="modalForm.processing" />
            </div>

          </q-card-section>

        </template>
        <template v-if="actionForm === 'mainchange'">

          <q-card-section  style="max-height: 85vh" class="canna_FormxContents scroll">
            <div class="subtitleAlt noselect">
              <div>
                La dirección de correo electrónico <span style="font-weight: 500;">remigio.garcia@hotmail.com</span> se convertirá en tu correo electrónico principal.
              </div>
            </div>

            <div class="buttonZone">
              <q-btn unelevated outline  :label="'Cancelar'" type="button" class="buttonSave_SE" color="app_mainBtn" :disabled="modalForm.processing" />

              <q-btn unelevated :label="'Sí, elegir como principal'" type="button" class="buttonSave_SE spacerRowTop24" color="app_mainBtn" :disabled="modalForm.processing" />
            </div>

          </q-card-section>

        </template>
        <!-- No se usan por el momento -->

        <!-- Loader de forma -->
        <!-- -->
        <q-inner-loading :showing="modalForm.processing">
          <q-spinner-orbit size="100px" color="indigo" />
        </q-inner-loading>
        
      </q-card>
    </q-dialog>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from '../../../boot/axios' // APP code 

import logOut_Mixin from '../../../mixins/requestMix.js'
import myplatform_Mixin from '../../../mixins/platformMix.js'
import helperBase_Mixin from '../../../mixins/helperBase.js'

export default {
  name: 'modalVerificaEmail_form',
  props: {
    actionForm: { // verify, add, remove, mainchange
      type: String,
      required: true,
      default: 'verify'
    },
    emailRecord:{
      type: Object,
      default: function () { 
        return { email: '' }
      }
    }
  },
  components: {
    cxIcon: require('components/appIcons.vue').default
  },
  mixins: [
    logOut_Mixin,
    myplatform_Mixin,
    helperBase_Mixin
  ],
  data () {
    return {
      version: '1.0.1',
      // -----------------
      myDifferify: differify, // para control en edicion 
      // -----------------
      initFormFlag: false,
      processingData:false, 
      allowBases: [],
      allowPaises: ['MX'],
      paisesCatalog:[
        {id:'MX', name: 'México', image:'paises_icons/mx.png'}
      ],
      alertActionShow:false,
      alertActionType:0,
      alertActionMsg:'Se envío correctamente un nuevo correo electrónico',
      modalForm: {
        show: false,
        formErrors: 0,
        formChangeErrors: 0,
        action: 'verify', // // add,  edit , dupx
        stepForm: 0, // 0 : ingresar datos ,  1:codigo de verificacion  2: Terminado / cerrar 
        processing: false, 
      },
      pomodoro:{ // para reenvio de correo 
        timerX:null, 
        canSendLink:true , 
        timerCount: 60 	 , //60 segundos
      }, 
      frmDataOriginal: {}, 
      frmData: {
        email: ''
      },
      frmDataChange:{
        email: null
      },
      frmMainErrors:{
        formCannaLog: '',
        formVEmail: '',
        formVChangeEmail:''
      },
      frmMainRefs:{
        formCannaLog: [
          {fieldNameAPi: 'email', fieldNameForm: 'email' },
          {fieldNameAPi: 'password', fieldNameForm: 'password' }
        ],
        formVEmail: [
        ],
        formVChangeEmail: [
          {fieldNameAPi: 'email', fieldNameForm: 'emailx' }
        ]
      },
      errores:{
        email: 'El dato ingresado no tiene el formato de un correo electrónico. Por favor, revisa e inténtalo de nuevo.', 
      }
    }
  },
  computed: {
    show: {
      get: function () {
        return this.$parent.frm_verifPhoneShow
      },
      set: function (value) {
        this.$parent.frm_verifPhoneShow = value
      }
    },
    parentX () {
      return this.$parent
    },
    mainErrorsTest () {
      // if (this.initFormFlag) {
      //   if (this.$refs.formESEVx) {
      //     return this.$refs.formESEVx.errors
      //   } else {
      //     return {}
      //   }
      // } else {
      //   return {}
      // }
      return {}
    },
    mainErrors () {
      var countx = 0
      // if (this.initFormFlag) {
      //   try {
      //     if (this.$refs.formESEVx) {
      //       if (this.$refs.formESEVx.errors) {
      //         var OBJKeys = Object.keys(this.$refs.formESEVx.errors)
      //         OBJKeys.forEach(itemX => {
      //           countx += this.$refs.formESEVx.errors[itemX].length
      //         })
      //       }
      //     }
      //   } catch (e) {
      //     console.log(e)
      //     countx += (-1)
      //   }
      // }
      return countx
    }
  },
  watch: {
    initFormFlag: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$forceUpdate()
      }
    },
    'modalForm.show': function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        //this.modalForm.datesErrors.show = false 
        this.$refs.dialogVEx.show()
      } else if (newVal !== oldVal && newVal === false) {
        // Se resetea la info del formulario 
        this.frmData =  {
          email: ''
        }
        //this.modalForm.datesErrors.show = false 
        this.$refs.dialogVEx.hide()
      }
      this.$forceUpdate()
    },
    'modalForm.stepForm': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$forceUpdate()
      }
    },
    'modalForm.processing': function (newVal, oldVal) {
      this.$forceUpdate()
    },
    alertActionShow: function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) { 
        setTimeout(() => {
          this.alertActionShow = false
        }, 7000)
      }
    }
  },
  methods: {
    ...requestPack,
    doShow () {
      this.modalForm.show = true
    },
    doHide () {
      this.modalForm.show = false
    },
    setShownDialog () {
      this.$cannaDebug('-- verificaPhoneForm -- set Shown Dialog')
      this.frmDataOriginal = { // Aqui se almacenan los datos en edicion (originales para comparacion) //Se resetea por cualquier cosa
        email: ''
      }
      this.frmDataChange = { email: null }
      // ---------------------
      //this.modalForm.stepForm = this.modalidad // ETapa o eventos 
      this.modalForm.action = this.actionForm
      //this.modalForm.datesErrors.show = false // Forzar al que el mensaje de fechas no aparezca 
      this.frmData.email = this.emailRecord.email

      if (this.modalForm.action === 'verify'){
        this.modalForm.stepForm = 0
        this.pomodoro = { // para reenvio de correo 
          timerX:null, 
          canSendLink:true , 
          timerCount: 60 	 , //60 segundos
        }
      }

      setTimeout(() => {
        this.onReset()
        // acciones para el  formulario 
        this.initFormFlag = true
        this.$forceUpdate()
      }, 200)
    },
    doCloseFinishDialog () { 
      this.initFormFlag = false
      this.onReset()
      this.$cannaDebug('-- verificaEmailForm -- doClose Finish Dialog')
      this.$emit('doCloseDialog', { newState: false, finish:true })
      this.modalForm.show = false
    },
    doCloseDialog (checkProcessing) {
      if (checkProcessing !== undefined) {
        if (this.modalForm.processing === true) {
          return
        }
      }
      this.initFormFlag = false
      this.onReset()
      this.$cannaDebug('-- verificaEmailForm -- do Close Dialog')
      this.$emit('doCloseDialog', { newState: false })
      //this.modalForm.datesErrors.show = false
      this.modalForm.show = false
    },
    onReset () {
      this.modalForm.formErrors = 0
      // this.modalForm.stepForm = 0
      this.modalForm.baseName = ''
      if (this.$refs.formESEVx) {
        this.$refs.formESEVx.reset()
      }
      this.$forceUpdate()
    },
    onResetChangeEmail () { 
      this.modalForm.formChangeErrors = 0
      this.frmDataChange = { email: null }
      if (this.$refs.formVChangeEmail) {
        this.$refs.formVChangeEmail.reset()
      }
      this.$forceUpdate()
    },
    // ---------------
    pomodoroStart: function() { 
        this.pomodoro.timerX = setInterval ( ()=> this.pomodoroDecreaseTimer(), 1000 ); 
    }, 
    pomodoroDecreaseTimer: function(){
      if (this.pomodoro.timerCount>=1){ 
        this.pomodoro.timerCount--; 
      } else { 
        this.pomodoro.timerCount=0;
        this.pomodoroHandlePause(); 
      }
    },
    pomodoroHandlePause: function (){ 
        clearInterval(this.pomodoro.timerX); 
        this.pomodoro.timerX= null;
        this.pomodoro.canSendLink = true;
        this.$forceUpdate();
    },
    // ---------------
    do_ChangeToEmailForm: function () {

      this.onResetChangeEmail()
      this.modalForm.stepForm = 2 
      
    },
    onSubmitChangeEmail: async function () { 
      this.$cannaDebug('-- verificaEmailForm -- on Submit Change Email')
      var test = 11
      if (this.mainErrors > 0) {
        return false
      }
      // ------------ Continuamos 
      this.modalForm.processing = true
      var mpModel = this.getModelRequest('userProfile')
      var mUModel = this.getModelRequest('userVerifica')  //// ${secure}://${server}/index.php/api/rum1/user/104/verify?notifier=email   
      var reqRes = { action: [], profileInfo: [], verifEmail:[] }
      var reqErr = { action: 0, profileInfo: 0, verifEmail:0 }
      var useVerifyRequest = false
      //Se hace patch al Profile para el nuevo EMAIL de la cuenta 

      var myProfilePasoB = this.createAxiosConfig ('patch', mpModel, {  useData:false, data: { email: '' + this.frmDataChange.email } }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'profilepersonales', reqRes.action, myProfilePasoB).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 44
        this.ex_handlerErrorFormRequest (
          reqRes.action[0], reqErr.action, this.$refs.formVChangeEmail, 'formVChangeEmail',  this.frmMainRefs.formVChangeEmail , 'formVChangeEmail'
        )
        this.modalForm.processing = false
        this.$forceUpdate()
        return false 
      }

      //Se recarga el profile DATA 
      var myProfileConfig = this.createAxiosConfig ('get', mpModel, {}, true, null)
      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profileInfo, myProfileConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.profileInfo[reqRes.profileInfo.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.profileInfo += 1
        }
      })
      if (reqErr.profileInfo > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 44
        this.ex_handlerErrorFormRequest (
          reqRes.profileInfo[0], reqErr.profileInfo, this.$refs.formVChangeEmail, 'formVChangeEmail',  this.frmMainRefs.formVChangeEmail , 'formVChangeEmail'
        )
        this.modalForm.processing = false
        this.$forceUpdate()
        return false 
      }

      // Actualizamos el STORE 
      var profileDataNew = reqRes.profileInfo[0].data.data
      this.frmData.email = '' + this.frmDataChange.email // Se actualiza el email ACtual 
      this.$store.commit('myapp/setMainState',{ appUserProfile: profileDataNew })
      await this._waitRequestProc(1000)

      if (useVerifyRequest ===true) {
        // ------------------
        // Se envia la verificacion de email....!
        var myEmailVerifConfig = this.createAxiosConfig ('post', mUModel, { delimiters:[this.getProfileData.id], urlmixData:{ notifier:'email'} }, false, null)
        // ------------------
        await this.doRequestByPromise(this.$axios, 'verifEmail', reqRes.verifEmail, myEmailVerifConfig).then((resx) => {
          // console.log ('Action -- verifica telefono Request -- POST ejecutado')
          var tmpresponseData = reqRes.verifEmail[reqRes.verifEmail.length - 1]
          if (tmpresponseData.type !== 'success') {
            reqErr.verifEmail += 1
          }
        })
        if (reqErr.verifEmail > 0) { // Si hay error de server, session o algo redireccionamos..!
          test = 55
          // handleError
          this.ex_handlerErrorFormNoSessionRequest (
            reqRes.verifEmail[0], reqErr.verifEmail, this.$refs.formVChangeEmail, 'formVChangeEmail',  this.frmMainRefs.formVChangeEmail , 'formVChangeEmail', false
          )
          this.modalForm.processing = false
          this.$forceUpdate()
          return false
        }
        await this._waitRequestProc(1000)
      }

      // ------------------
      // lo enviamos a la pantalla 1  (de confirmacion)
      // this.modalForm.stepForm = 1 
      this.modalForm.stepForm = 1 

      // Start pomodoro data 
      this.pomodoro.canSendLink = false;
      this.pomodoro.timerCount = 60;
      this.pomodoroStart();

      this.$forceUpdate()
      setTimeout(() => {

        this.alertActionType = 0
        this.alertActionMsg = 'Se envío correctamente el correo electrónico',
        this.modalForm.processing = false
        this.alertActionShow = true

      }, 200)
      return true
    },
    onSubmitClose: function () {
      var test = 11
    },
    onSubmit: async function ( isResendParam ) {
      this.$cannaDebug('-- verificaEmailForm -- on Submit')
      if (this.mainErrors > 0) {
        return false
      }
      // ------------ Continuamos 
      this.modalForm.processing = true
      // ------------------
      var test = 11
      this.$cannaDebug('-- loginscreen -- onResetPassSubmit')
      var test = 11
      var mpModel = this.getModelRequest('userVerifica')  //// ${secure}://${server}/index.php/api/rum1/user/104/verify?notifier=email   
      var reqRes = { action: [], profile: [] }
      var reqErr = { action: 0, profile: 0 }

      // Se envia la verificacion de email....!
      var myPhoneVerifConfig = this.createAxiosConfig ('post', mpModel, { delimiters:[this.getProfileData.id], urlmixData:{ notifier:'email'} }, false, null)
      // // ------------------
      await this.doRequestByPromise(this.$axios, 'verifPhone', reqRes.action, myPhoneVerifConfig).then((resx) => {
        // console.log ('Action -- verifica telefono Request -- POST ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        // handleError
        this.ex_handlerErrorFormNoSessionRequest (reqRes.action[0], reqErr.action, this.$refs.formVEmail, 'formVEmail',  this.frmMainRefs.formVEmail , 'formVEmail', false)
        this.modalForm.processing = false
        return false
      }
      await this._waitRequestProc(1000)
      // ------------------

      // Todo bien no hubo errores 
      if (isResendParam===undefined || isResendParam===null || isResendParam===''){
        this.modalForm.stepForm = 1 
        this.$forceUpdate()
        setTimeout(() => {

          this.alertActionType = 0
          this.alertActionMsg = 'Se envío correctamente el correo electrónico',
          this.modalForm.processing = false
          this.alertActionShow = true

        }, 400)

      } else if ( isResendParam === true ) {

        // Start pomodoro data 
        this.pomodoro.canSendLink = false;
        this.pomodoro.timerCount = 60;
        this.pomodoroStart();

        this.$forceUpdate()
        setTimeout(() => {
          this.alertActionType = 0
          this.alertActionMsg = 'Se envío correctamente un nuevo correo electrónico',
          this.modalForm.processing = false
          this.alertActionShow = true
        }, 400)

      }
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- verificaEmailForm -- beforeCreate')
    this.initFormFlag = false
  },
  created () {
    this.$cannaDebug('-- verificaEmailForm -- created')
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- verificaEmailForm --  beforeMount')
  },
  mounted () {
    this.$cannaDebug('-- verificaEmailForm -- mounted')
    //LLENAR los puestos Valido
    //this.initFormFlag = true
  },
  beforeUpdate () {
    // this.$cannaDebug('-- verificaEmailForm -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- verificaEmailForm -- updated')
  },
  activated () {
    // this.$cannaDebug('-- verificaEmailForm -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- verificaEmailForm -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- verificaEmailForm -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- verificaEmailForm -- destroyed')
  }
}
</script>
