<template>
    <q-dialog 
      ref="dialogVPx"
      v-model="modalForm.show"
      content-class="cannaFormModal"
      transition-show="scale" 
      transition-hide="scale"
      :persistent="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-width="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-height="(is_Mobile|| $q.screen.lt.sm)?true:false"
      :no-esc-dismiss="(is_Mobile|| $q.screen.lt.sm)?false: ((modalForm.processing) ? true : false)"
      :no-backdrop-dismiss="(is_Mobile|| $q.screen.lt.sm)?false:true"
      @show="setShownDialog"
      @escape-key="doCloseDialog(true)"
    >
      <q-card class="canna_Formx ">
        <!-- encabezado -->
        <q-card-section horizontal class="q-card q-pa-md flex justify-between column noselect">
          <div class="closeButton">
            <cxIcon
              iconType="ui_close"
              size="24px"
              class=""
              :cls="'icon_close_svg'"
              :state="(modalForm.processing === false) ? 0 : 2"
              :hasMouseOver="true"
              :hasAction="true"
              :allowDisabled="true"
              :allowClick="true"
              @iconClick="doCloseDialog"
            />
          </div>
          <div class="form_title">

            <template v-if="actionForm === 'remove'">

              <span>Eliminar número celular</span>

            </template>

            <template v-else-if="actionForm === 'mainchange'">

              <span>Cambiar número principal</span>

            </template>

            <template v-else><!-- // verify, add -->
              <template v-if="modalForm.stepForm!==2">
                <template v-if="actionForm==='add' && modalForm.stepForm===0">
                    <span>Agregar un número celular</span>
                </template>
                <template v-else>
                  <template v-if="modalForm.stepForm===1">  
                    <span>Corregir mi número celular</span>
                  </template>
                  <template v-else>
                    <span>Verificar mi número celular</span>
                  </template>
                </template>
              </template>
              <template v-else>
                <span>Tu número de teléfono celular <template v-if=" $q.screen.gt.sm"><br></template> ha sido verificado</span>
              </template>

              <template v-if="modalForm.formErrors>0">
                <div class="subtitleAlt subtitleError">
                  Hay elementos que necesitan tu atención
                </div>
              </template>

            </template>

          </div>
        </q-card-section>

        <q-separator />
        <!-- formulario -->
        <!-- Seccion :: verify, add  -->

        <q-card-section  style="max-height: 85vh" class="canna_FormxContents scroll" :style="(actionForm === 'verify' || actionForm === 'add') ? '': 'display:none;'">
          <!-- Contenido del formulario -->

          <!-- PASO 1 -->
          <!-- Zona de notificacion -->
          <template v-if="(modalForm.stepForm===0 && alertActionShow)">
            <div class="notifyZone noselect" :class="(alertActionType!==0) ? 'notifyZoneRemove' : ''">
              <div style="flex: 1;justify-content: center;">
                  <q-icon name="check_circle" style="color:#ffffff; font-size: 22px; margin-right: 10px;" />
                  {{ alertActionMsg }}
              </div>
              <div style="margin-right: 14px;">
                  <div class="closenotifyZoneIcon action_item" @click.stop=" alertActionShow = false ">
                    <q-icon name="clear" style="color:#ffffff; font-size: 20px;" />
                  </div>
              </div>
            </div>
          </template>

          <div class="subtitleAlt noselect" :style="(modalForm.stepForm===0) ? '': 'display:none;'">
            <div>
              Cuando finalizaste tu registro recibiste un código de verificación, que será válido por 24 horas.
              <!-- Hemos enviado un código de verificación único que será válido por las próximas 24 horas. -->
            </div>
            <div class="spacerRowTop24">
              Ingresa los 5 dígitos del código que enviamos al número celular 
              <span style="color: #24222A;font-weight: 500;"> {{frmData.telefonoMasked}} </span>
            </div>
          </div>

          <ValidationObserver tag="div" ref="formVPhoneCoder" v-slot="{ handleSubmit, valid, passed }" :style="(modalForm.stepForm===0) ? '': 'display:none;'">
            <q-form
              ref="myformVPhoneCoder"
              @submit.prevent="handleSubmit(onSubmitVerifyCode)"
              @reset="onReset"
              class="canna_forma_zona "
              style=""
            > 
              <div class="row spacerRowTop24 q-gutter-md justify-center">
                <ValidationProvider class="col colSMSCode" rules="required|cannaSMSCode|emojiFree|min:1|max:1" 
                  name="code_a" :immediate="false" v-slot="{ invalid, validated  }">
                  <div class="canna_forma_campo_container" style="max-width: 50px; margin-bottom: 0px;">
                    <q-input
                      ref="fldsms_code_a"
                      name="code_a"
                      ctrl-ref-prev=""
                      ctrl-ref-next="code_b"
                      maxlength="1"
                      filled
                      v-model="frmData.verificaCodeData[0]"
                      :mask="'#'"
                      unmasked-value
                      :no-error-icon="true"
                      :hint="''"
                      :dense="true"
                      :label="''"
                      :error="invalid && validated"
                      @keyup.prevent="sms_phoneKeyUp($event)"
                    />
                    <!-- :error-message="(errors[0] !==undefined) ?  errors[0].replace('lada','La clave lada').replace('El campo','') : ''" -->
                  </div>
                </ValidationProvider>

                <ValidationProvider class="col colSMSCode" rules="required|cannaSMSCode|emojiFree|min:1|max:1" name="code_b" :immediate="false" v-slot="{ invalid, validated  }">
                  <div class="canna_forma_campo_container" style="max-width: 50px; margin-bottom: 0px;">
                    <q-input
                      ref="fldsms_code_b"
                      name="code_b"
                      ctrl-ref-prev="code_a"
                      ctrl-ref-next="code_c"
                      maxlength="1"
                      filled
                      v-model="frmData.verificaCodeData[1]"
                      :mask="'#'"
                      unmasked-value
                      :no-error-icon="true"
                      :hint="''"
                      :dense="true"
                      :label="''"
                      :error="invalid && validated"
                      @keyup.prevent="sms_phoneKeyUp($event)"
                    />
                    <!-- :error-message="(errors[0] !==undefined) ?  errors[0].replace('lada','La clave lada').replace('El campo','') : ''" -->
                  </div>
                </ValidationProvider>

                <ValidationProvider class="col colSMSCode" rules="required|cannaSMSCode|emojiFree|min:1|max:1" name="code_c" :immediate="false" v-slot="{ invalid, validated  }">
                  <div class="canna_forma_campo_container" style="max-width: 50px; margin-bottom: 0px;">
                    <q-input
                      ref="fldsms_code_c"
                      name="code_c"
                      ctrl-ref-prev="code_b"
                      ctrl-ref-next="code_d"
                      maxlength="1"
                      filled
                      v-model="frmData.verificaCodeData[2]"
                      :mask="'#'"
                      unmasked-value
                      :no-error-icon="true"
                      :hint="''"
                      :dense="true"
                      :label="''"
                      :error="invalid && validated"
                      @keyup.prevent="sms_phoneKeyUp($event)"
                    />
                    <!-- :error-message="(errors[0] !==undefined) ?  errors[0].replace('lada','La clave lada').replace('El campo','') : ''" -->
                  </div>
                </ValidationProvider>

                <ValidationProvider class="col colSMSCode" rules="required|cannaSMSCode|emojiFree|min:1|max:1" name="code_d" :immediate="false" v-slot="{ invalid, validated  }">
                  <div class="canna_forma_campo_container" style="max-width: 50px; margin-bottom: 0px;">
                    <q-input
                      ref="fldsms_code_d"
                      name="code_d"
                      ctrl-ref-prev="code_c"
                      ctrl-ref-next="code_e"
                      maxlength="1"
                      filled
                      v-model="frmData.verificaCodeData[3]"
                      :mask="'#'"
                      unmasked-value
                      :no-error-icon="true"
                      :hint="''"
                      :dense="true"
                      :label="''"
                      :error="invalid && validated"
                      @keyup.prevent="sms_phoneKeyUp($event)"
                    />
                    <!-- :error-message="(errors[0] !==undefined) ?  errors[0].replace('lada','La clave lada').replace('El campo','') : ''" -->
                  </div>
                </ValidationProvider>

                <ValidationProvider class="col colSMSCode" rules="required|cannaSMSCode|emojiFree|min:1|max:1" name="code_e" :immediate="false" v-slot="{ invalid, validated  }">
                  <div class="canna_forma_campo_container" style="max-width: 50px; margin-bottom: 0px;">
                    <q-input
                      ref="fldsms_code_e"
                      name="code_e"
                      ctrl-ref-prev="code_d"
                      ctrl-ref-next=""
                      maxlength="1"
                      filled
                      v-model="frmData.verificaCodeData[4]"
                      :mask="'#'"
                      unmasked-value
                      :no-error-icon="true"
                      :hint="''"
                      :dense="true"
                      :label="''"
                      :error="invalid && validated"
                      @keyup.prevent="sms_phoneKeyUp($event)"
                    />
                    <!-- :error-message="(errors[0] !==undefined) ?  errors[0].replace('lada','La clave lada').replace('El campo','') : ''" -->
                  </div>
                </ValidationProvider>
              </div>

              <template v-if="(frmMainErrors.formVPhoneCoder !== '')">
                <div class="canna_frmMainErrorZone spacerRowTop28" style="display: flex;flex-direction: row;align-items: center;">
                  <div style="margin-right:10px;">
                    <img src="images/login_warning.png"/>
                  </div>
                  <div style="flex:1;" v-html="frmMainErrors.formVPhoneCoder"></div>
                </div>
              </template>

              <div class="buttonZone" style="margin-top: 36px !important;">
                <q-btn 
                  type="submit" class="buttonSave_SE" color="app_mainBtn" 
                  unelevated 
                  :label="'Completar verificación'"
                  :disabled="modalForm.processing" 
                  :disable="((passed || valid) && initFormFlag )? false : true"
                />
              </div>

              <div class="noselect subtitleAlt spacerRowTop48" style="text-align: center;">
                <div>¿Aún no has recibido el código de verificación?</div>
                <template v-if="pomodoro.canSendLink===false">
                  <div class="spacerRowTop4">Podrás solicitar un nuevo código en {{pomodoro.timerCount}} segundos.</div>
                </template>
                <template v-if="pomodoro.canSendLink===true">
                  <div class="spacerRowTop4 action_item actionLabel actionLabelAlt" @click.stop="onSubmitVerifyA(true, true)">
                    Solicita un nuevo código
                  </div>
                </template>
              </div>

              <div class="noselect subtitleAlt spacerRowTop24" style="text-align: center;" v-if="pomodoro.canSendLink===true">
                <div>¿Tu número celular está mal escrito?</div>
                <template v-if="pomodoro.canSendLink===true">
                  <div class="spacerRowTop4 action_item actionLabel actionLabelAlt" @click.stop="do_changeTelephoneAction()">
                    Corregir número celular
                  </div>
                </template>
              </div>

            </q-form>
          </ValidationObserver>
          <!-- Zona de notificacion ::termina -->

          <!-- PASO 2 modificar celular (si es necesario...!) -->
          <!-- Modificar Celular --> 
          <div class="subtitleAlt noselect" :style="(modalForm.stepForm===1) ? '': 'display:none;'">
            <template v-if="actionForm==='add'">
              <div>
                Después de agregar el nuevo número celular enviaremos un mensaje de texto que contendrá un código de verificación.
              </div>
            </template>
            <template v-else>
              <div>
                Escribe correctamente tu número celular y enviaremos un mensaje de texto que contendrá un código de verificación.
              </div>
            </template>
          </div>

          <ValidationObserver tag="div" ref="formVPhonex" v-slot="{ handleSubmit, valid, passed }" :style="(modalForm.stepForm===1) ? '': 'display:none;'"> 
            <q-form
              ref="myPhoneVerifyForm"
              @submit.prevent="handleSubmit(onSubmitVerifyA)"
              @reset="onReset"
              class="canna_forma_zona"
              style=""
            >
              <!-- Campo Pais (por el moento solo mexico) -->
              <ValidationProvider :rules=" { required: true, oneOf: allowPaises } " ref="fldx_pais" name="pais" v-slot="{ errors, invalid, validated  }">
                <div class="canna_forma_campo_container_SE">
                  <div style="display: flex;justify-content: space-between;">
                    <div class="fieldLabel text-left" :class="{ 'fieldError':invalid && validated }">PAÍS</div>
                    <div class="requiredLabel text-right" style="display:none;">Obligatorio</div>
                  </div>
                  <q-select
                    filled
                    :readonly="true"
                    :disable="true"
                    :dense="true"
                    name="pais"
                    v-model="frmData.pais"
                    :label="(frmData.pais==='' || frmData.pais===null) ? 'Elige un país' : ''"
                    :options="paisesCatalog"
                    option-value="id"
                    option-label="name"
                    emit-value
                    map-options
                    :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                    :error="invalid && validated"
                    :error-message="(errors[0] !==undefined) ?  errors[0].replace('pais','País').replace('El campo','') : ''"
                  >
                    <!-- 
                    <template v-slot:append>
                      <q-icon v-if="frmData.pais !== null" class="cursor-pointer" name="clear" @click.stop="frmData.pais = null"/>
                    </template>
                    -->
                    <template v-slot:selected-item="scope">

                      <template v-if="frmData.pais!==null && frmData.pais !==''">
                        <div class="justify-left" style="display: flex;flex-direction: row;align-items: center;">
                          <div style="margin-right: 10px;">
                            <div style="width:20px; height:15px; border-radius:0px;">
                              <img :src="scope.opt.image" style="width:20px; height:15px;">
                            </div>
                          </div>
                          <q-item-label v-html="scope.opt.name" />
                        </div>
                      </template>

                    </template>
                    <template v-slot:option="scope">
                      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                        <q-item-section  >
                          <div class="justify-left" style="display: flex;flex-direction: row;align-items: center;">
                            <div style="margin-right: 10px;">
                              <div style="width:20px; height:15px; border-radius:0px;">
                                <img :src="scope.opt.image" style="width:20px; height:15px;">
                              </div>
                            </div>
                            <q-item-label v-html="scope.opt.name" />
                          </div>
                        </q-item-section>
                      </q-item>
                    </template>

                  </q-select>
                </div>
              </ValidationProvider>

              <!-- Campo de Telefono (lada y numero) -->
              <div class="row rowFXFix_last" style="">

                <div class="col-12 fieldLabel text-left" >TU NÚMERO CELULAR </div>

                <ValidationProvider rules="required|cannaPhones|emojiFree|min:3|max:3" name="lada" :immediate="false" v-slot="{ errors, invalid, validated  }">
                  <div class="canna_forma_campo_container" style="max-width: 120px; margin-bottom: 0px;">
                    <q-input
                      filled
                      :readonly="true"
                      :disable="true"
                      name="lada"
                      v-model="frmData.lada"
                      hint=""
                      :dense="true"
                      :label="(frmData.lada==='')? '':''"
                      :error="invalid && validated"
                      :error-message="(errors[0] !==undefined) ?  errors[0].replace('lada','La clave lada').replace('El campo','') : ''"
                    />
                  </div>
                </ValidationProvider>

                <ValidationProvider rules="required|cannaPhones|emojiFree|min:10|max:10" name="telefono" :immediate="false" v-slot="{ errors, invalid, validated  }">
                  <div class="canna_forma_campo_container" style="margin-left:10px; margin-bottom: 6px;">
                    <q-input
                      filled
                      name="telefono"
                      v-model="frmData.telefono"
                      hint=""
                      :dense="true"
                      mask="(##) #### ####"
                      unmasked-value
                      :label="(frmData.telefono==='')? '':''"
                      :error="invalid && validated"
                      :error-message="(errors[0] !==undefined) ?  errors[0].replace('telefono','El telefono').replace('El campo','') : ''"
                      @blur="pasoChange_onBlurCampo('telefono', $event)"
                    />
                  </div>
                </ValidationProvider>

                <!--
                  <div class="col-12 fieldLabel text-left noselect" style="margin-bottom: 0px; font-weight: 400;text-transform: none;" >
                    Este número celular es el que registraste en tu cuenta de Canna, si lo requieres puedes modificarlo.
                  </div>
                -->
              </div>

              <template v-if="(frmMainErrors.formVPhonex !== '')">
                <div class="canna_frmMainErrorZone spacerRowTop28" style="display: flex;flex-direction: row;align-items: center;">
                  <div style="margin-right:10px;">
                    <img src="images/login_warning.png"/>
                  </div>
                  <div style="flex:1;" v-html="frmMainErrors.formVPhonex"></div>
                </div>
              </template>

              <div class="buttonZone">
                <q-btn type="submit" class="buttonSave_SE" color="app_mainBtn" unelevated :label="'Solicitar mensaje de texto'"
                  :disable="((passed || valid))? false : true"
                />
              </div>

            </q-form>
          </ValidationObserver>
          <!-- Modificar Celular :: Termina --> 


          <!-- PASO 3 (final de Verificacion) -->
          <div v-if="(modalForm.stepForm===2)">

            <q-form
              ref="myPhoneVerifyFinishForm"
              class="canna_forma_zona "
              style=""
            > 
              <div style="display: flex;align-items: center;justify-content: center;">
                <div>
                  <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M63.5 36C63.5 50.9117 51.4117 63 36.5 63C21.5883 63 9.5 50.9117 9.5 36C9.5 21.0883 21.5883 9 36.5 9C51.4117 9 63.5 21.0883 63.5 36Z" fill="#7156DC"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M50.2646 21.574C51.6045 22.5485 51.9008 24.4248 50.9262 25.7647L35.6535 46.7647C35.158 47.4461 34.3987 47.8877 33.5614 47.9816C32.7241 48.0754 31.8859 47.8128 31.2518 47.2579L22.5245 39.6216C21.2776 38.5305 21.1513 36.6352 22.2423 35.3883C23.3334 34.1414 25.2286 34.0151 26.4756 35.1061L32.7321 40.5806L46.0738 22.2357C47.0483 20.8957 48.9246 20.5995 50.2646 21.574Z" fill="white"/>
                  </svg>
                </div>
              </div>
              <div class="buttonZone" style="margin-top: 36px !important;">
                <q-btn 
                  unelevated 
                  :label="'Cerrar'"
                  type="button" class="buttonSave_SE" color="app_mainBtn"
                  @click.stop="doCloseFinishDialog"
                />
              </div>

            </q-form>

          </div>

        </q-card-section>

        <!-- NO usados por el momento --> 
        <template v-if="actionForm === 'remove'">

          <q-card-section  style="max-height: 85vh" class="canna_FormxContents scroll">
            <div class="subtitleAlt noselect">
              <div>
                Estás seguro de eliminar el número celular con terminación <span style="font-weight: 500;">+XX (XX) XXXX XX58</span>
              </div>
            </div>

            <div class="buttonZone" style="margin-top: 80px !important;">
              <q-btn unelevated outline  :label="'Cancelar'" type="button" class="buttonSave_SE" color="app_mainBtn" :disabled="modalForm.processing" />

              <q-btn unelevated :label="'Eliminar número celular'" type="button" class="buttonSave_SE spacerRowTop24" color="app_mainBtn" :disabled="modalForm.processing" />
            </div>

          </q-card-section>

        </template>
        <template v-if="actionForm === 'mainchange'">

          <q-card-section  style="max-height: 85vh" class="canna_FormxContents scroll">
            <div class="subtitleAlt noselect">
              <div>
                El número celular con terminación <span style="font-weight: 500;">+XX (XX) XXXX XX58</span> se convertirá en tu número de contacto principal.
              </div>
            </div>

            <div class="buttonZone">
              <q-btn unelevated outline  :label="'Cancelar'" type="button" class="buttonSave_SE" color="app_mainBtn" :disabled="modalForm.processing" />

              <q-btn unelevated :label="'Sí, elegir como principal'" type="button" class="buttonSave_SE spacerRowTop24" color="app_mainBtn" :disabled="modalForm.processing" />
            </div>

          </q-card-section>

        </template>
        <!-- NO usados por el momento --> 


        <!-- Loader de forma -->
        <q-inner-loading :showing="modalForm.processing">
          <q-spinner-orbit size="100px" color="indigo" />
        </q-inner-loading>
        <!-- -->
      </q-card>



    </q-dialog>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from '../../../boot/axios' // APP code 

import logOut_Mixin from '../../../mixins/requestMix.js'
import myplatform_Mixin from '../../../mixins/platformMix.js'
import helperBase_Mixin from '../../../mixins/helperBase.js'

export default {
  name: 'modalVerificaPhone_form',
  props: {
    actionForm: { // verify, add, remove , mainchange
      type: String,
      required: true,
      default: 'verify'
    },
    phoneRecord:{
      type: Object,
      default: null
    }
  },
  components: {
    cxIcon: require('components/appIcons.vue').default
  },
  mixins: [
    logOut_Mixin,
    myplatform_Mixin,
    helperBase_Mixin
  ],
  data () {
    return {
      version: '1.0.1',
      // -----------------
      myDifferify: differify, // para control en edicion 
      // -----------------
      initFormFlag: false,
      processingData:false, 
      allowBases: [],
      allowPaises: ['MX'],
      paisesCatalog:[
        {id:'MX', name: 'México', image:'paises_icons/mx.png'}
      ],
      alertActionShow:false,
      alertActionType:0,
      alertActionMsg:'Se envío correctamente un nuevo mensaje de texto',
      modalForm: {
        show: false,
        formErrors: 0,
        action: 'verify', // // add,  edit , dupx
        stepForm: 0, // 0 : ingresar datos ,  1:codigo de verificacion  2: Terminado / cerrar 
        processing: false, 
        processingSMS:false,
      },
      pomodoro:{ // para reenvio de correo 
        timerX:null, 
        canSendLink:true , 
        timerCount: 60 , //60 segundos
      }, 
      frmDataOriginal: {},
      frmData: {
        pais: 'MX',
        lada: '+52',
        telefono: '',
        telefonoMasked:'',
        verificaCode: '',
        verificaCodeData:['','','','','']
      },
      frmMainErrors:{
        formVPhonex: '',
        formVPhoneCoder: ''
      },
      frmMainRefs:{
        formVPhonex: [
          {fieldNameAPi: 'phone', fieldNameForm: 'telefono' }
        ],
        formVPhoneCoder: [
        ]
      }
    }
  },
  computed: {
    show: {
      get: function () {
        return this.$parent.frm_verifPhoneShow
      },
      set: function (value) {
        this.$parent.frm_verifPhoneShow = value
      }
    },
    parentX () {
      return this.$parent
    },
    mainErrorsTest () {
      if (this.$refs.formVPhonex) {
        return this.$refs.formVPhonex
      } else {
        return {}
      }
    },
    mainErrors () {
      var countx = 0
      if (this.initFormFlag) {
        try {
          if (this.$refs.formVPhonex) {
            if (this.$refs.formVPhonex.errors) {
              var OBJKeys = Object.keys(this.$refs.formVPhonex.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formVPhonex.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    },
    mainErrorsVerifCode () {
      var countx = 0
      if (this.initFormFlag) {
        try {
          if (this.$refs.formVPhoneCoder) {
            if (this.$refs.formVPhoneCoder.errors) {
              var OBJKeys = Object.keys(this.$refs.formVPhoneCoder.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formVPhoneCoder.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    }
  },
  watch: {
    initFormFlag: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$forceUpdate()
      }
    },
    'modalForm.show': function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        //this.modalForm.datesErrors.show = false 
        this.$refs.dialogVPx.show()
      } else if (newVal !== oldVal && newVal === false) {
        // Se resetea la info del formulario 
        this.frmData = {
          pais: 'MX',
          lada: '+52',
          telefono: '',
          telefonoMasked:'',
          verificaCode: '',
          verificaCodeData:['','','','','']
        }
        this.frmDataOriginal = {}
        //this.modalForm.datesErrors.show = false 
        this.$refs.dialogVPx.hide()
      }
      this.$forceUpdate()
    },
    'modalForm.stepForm': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$forceUpdate()
      }
    },
    'modalForm.processing': function (newVal, oldVal) {
      this.$forceUpdate()
    },
    alertActionShow: function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) { 
        setTimeout(() => {
          this.alertActionShow = false
        }, 6000)
      }
    }
  },
  methods: {
    ...requestPack,
    doShow () {
      this.modalForm.show = true
    },
    doHide () {
      this.modalForm.show = false
    },
    setShownDialog () {
      this.$cannaDebug('-- verificaPhoneForm -- set Shown Dialog')
      // ---------------------
      //this.modalForm.stepForm = this.modalidad // ETapa o eventos 
      this.modalForm.action = this.actionForm
      //this.modalForm.datesErrors.show = false // Forzar al que el mensaje de fechas no aparezca 
      if (this.modalForm.action === 'verify'){
        this.modalForm.stepForm = 0
        this.frmData =  {
          pais: 'MX',
          lada: '+52',
          telefono: '' + this.phoneRecord.phone.replace('+52',''), 
          telefonoMasked: this.getPhoneDataMasked(2, (this.phoneRecord.phone)),
          verificaCode: '',
          verificaCodeData:['','','','','']
        }
        this.frmDataOriginal = {
          pais: 'MX',
          lada: '+52',
          telefono: '' + this.phoneRecord.phone.replace('+52',''), 
          telefonoMasked: this.getPhoneDataMasked(2, (this.phoneRecord.phone)),
          verificaCode: '',
          verificaCodeData:['','','','','']
        }
        this.$refs.formVPhonex.validate() 
      }
      setTimeout(() => {
        //this.onReset()
        // acciones para el  formulario 
        this.initFormFlag = true
        this.$forceUpdate()
      }, 200)
    },
    doCloseFinishDialog () { 
      this.initFormFlag = false
      this.onReset()
      this.$cannaDebug('-- verificaPhoneForm -- doClose Finish Dialog')
      this.$emit('phoneVerified', { newState: false, finishVerifica:true })
      this.modalForm.show = false
    },
    doCloseDialog (checkProcessing) {
      if (checkProcessing !== undefined) {
        if (this.modalForm.processing === true) {
          return
        }
      }
      this.initFormFlag = false
      this.onReset()
      this.$cannaDebug('-- verificaPhoneForm -- do Close Dialog')
      this.$emit('doCloseDialog', { newState: false })
      //this.modalForm.datesErrors.show = false
      this.modalForm.show = false
    },
    onReset () {
      this.modalForm.formErrors = 0
      // this.modalForm.stepForm = 0
      this.modalForm.baseName = ''
      if (this.$refs.formVPhonex) {
        this.$refs.formVPhonex.reset()
      }
      this.$forceUpdate()
    },
    // ---------------
    sms_phoneKeyUp( event ) { //@keyup
      var test=11
      var testEv = event 
      event.stopPropagation();
      var Elx = event.target 
      var testInputs = Elx.hasAttribute('ctrl-ref-next')
      if (testInputs!==true || this.is_Mobile === true){ 
        return
      }
      var nxP = Elx.getAttribute('ctrl-ref-prev')
      var nxC = Elx.getAttribute('ctrl-ref-next')
      var nx = Elx.getAttribute('name')
      var isAndroidz = this.is_Androidx 
      if (isAndroidz === true ){  //Hack for android
        var kc = event.which || event.keyCode;
        if ( kc == 0 || kc == 229){
          var ss = Elx.selectionStart - 1;
          var ssv = ss || 0;
          var char = Elx.value.substr(ssv, 1);
          kc = char.charCodeAt(0);
        }
        if (kc == 8) { // BackSpace //console.log("backspace presionado... -- android ");
            if (Elx.value === "" && nxP !== ''){
                var nameRefBA ='fldsms_' + nxP
                if (this.$refs[nameRefBA]){
                  this.$refs[nameRefBA].focus()
                }
                return;
            }
        }
        // let regex = /^[0-9]*$/;
        // if (!regex.test(Elx.value)) {
        //   Elx.value = Elx.value.slice(0, -1);
        // }
        if( (kc >=48 && kc <=57)  || (kc >=96 && kc <=105) )  {  // Puro numero 
            if (nxC!==''){
                var valtest= '' + Elx.value;
                if (valtest.length === 1){ //console.log('---testDirective autoTabx -- focusing newFocusElId'); 
                  var nameRefNA ='fldsms_' + nxC
                  if (this.$refs[nameRefNA]){
                    this.$refs[nameRefNA].focus()
                  }
                  return;
                }
            }
        } else {  //do nothing 
            // vnode.context[controlDataRef][controlDataIndex] = "" +  el.value; 
            // el.focus();
            // el.select();
            return;
        }
      } else {
        if( event.keyCode ==8 ){ //BackSpace 
            //console.log('backspace presionado...');
            if (Elx.value === "" && nxP !== ''){
                var nameRefBA ='fldsms_' + nxP
                if (this.$refs[nameRefBA]){
                  this.$refs[nameRefBA].focus()
                }
                return;
            }
        }
        // let regex = /^[0-9]*$/;
        // if (!regex.test(Elx.value)) {
        //   Elx.value = Elx.value.slice(0, -1);
        // }
        if( (event.keyCode >=48 && event.keyCode <=57)  || (event.keyCode >=96 && event.keyCode <=105) )  {  // Puro numero 
            if (nxC!==''){
                var valtest= '' + Elx.value;
                if (valtest.length === 1){ //console.log('---testDirective autoTabx -- focusing newFocusElId'); 
                  var nameRefNA ='fldsms_' + nxC
                  if (this.$refs[nameRefNA]){
                    this.$refs[nameRefNA].focus()
                  }
                  return;
                }
            }
        } else {  //do nothing 
            // vnode.context[controlDataRef][controlDataIndex] = "" +  el.value; 
            // el.focus();
            // el.select();
            return;
        }
      }
    }, 
    getPhoneDataMasked (tipoDato , valor ) {
      var myTel = valor
     
      if (tipoDato === 1 && valor.length===13) {  // +XX (XX) XXXX XX90
        var tel = myTel.split('')
        tel.splice(3, 0, ' (');
        tel.splice(6, 0, ') ');
        tel.splice(11, 0, ' ');
        var prueba = tel.join('')
        return '' + prueba
      } else if (tipoDato === 2 && valor.length===13) {  // +XX (XX) XXXX XX90
        var telb = myTel.split('')
        telb.splice(3, 0, ' (XX) XXXX XX');
        telb.splice(4, 8);
        var pruebaB = telb.join('')
        return '' + pruebaB
        // var mytel = "+525572215548".split("")
        // console.log(mytel)
        // mytel.splice(3, 0, ' (XX) XXXX XX');
        // console.log(mytel)
        // mytel.splice(4, 8);
        // console.log(mytel)
      } else { 
        return '----'
      }
    },
    // ---------------
    do_changeTelephoneAction () {
      this.modalForm.stepForm = 1
      this.$forceUpdate()
    },
    // ---------------
    pomodoroStart: function() {
      if (this.pomodoro.timerX!==null){ 
        return 
      }
      this.pomodoro.timerX = setInterval ( ()=> this.pomodoroDecreaseTimer(), 1000 );
    }, 
    pomodoroDecreaseTimer: function(){
      if (this.pomodoro.timerCount>=1){ 
        this.pomodoro.timerCount--; 
      } else { 
        this.pomodoro.timerCount=0;
        this.pomodoroHandlePause(); 
      }
    },
    pomodoroHandlePause: function (){ 
        clearInterval(this.pomodoro.timerX); 
        this.pomodoro.timerX = null;
        this.pomodoro.canSendLink = true;
        this.$forceUpdate();
    },
    // ---------------
    // ---------------
    // PAra telefono  (cambio)
    pasoChange_onBlurCampo: function (fieldName, event){
      this.$cannaDebug('-- verificaPhoneForm -- pasoB onBlur Campo')
      if (this.frmMainErrors.formVPhonex!=''){ 
        this.frmMainErrors.formVPhonex = ''
        this.$forceUpdate()
      }
      if (fieldName === 'telefono'){
        this.pasoChange_validatePhone()
      }
    },
    pasoChange_validatePhone: async function(){ 
      var test = 11
      var reqRes = { action: [] }
      var reqErr = { action: 0 }
      var myPhone = ('+52' + this.frmData.telefono).trim()
      if (myPhone.length!==13){
        return true 
      }
      var mpModel = this.getModelRequest('telefonoCheck')
      var myTelCfg = this.createAxiosConfig ('get', mpModel, { numero: myPhone }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'profilepersonales', reqRes.action, myTelCfg).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // no deberia haber errores nunca, solo es consulta 
        test = 44
        this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formVPhonex, 'formVPhonex',  this.frmMainRefs.formVPhonex , 'formVPhonex')
        this.loading_Process = false
        this.$forceUpdate()
        return false 
      }
      var telData = reqRes.action[0].data.data
      if (telData.length<=0){
        // Mostrar error q no existe el telefono 
        var myObjectNoPhone = { 
          telefono: 'El número no existe o no corresponde a un número celular. Por favor, revisa e inténtalo de nuevo.'
        }
        this.frmMainErrors.pasoB = 'El número no existe o no corresponde a un número celular. Por favor, revisa e inténtalo de nuevo.'
        this.$refs.formVPhonex.setErrors(myObjectNoPhone);
        this.$forceUpdate()
        return false 
      } else {
        // checar si es movil o FIJO
        var myREcord = telData[0]
        //this.accountData.data.telefonoTipo = myREcord.tipo_red
        if (myREcord.tipo_red!=='MOVIL'){
          var myObjectNoPhone = {
            telefono: 'El número ingresado no corresponde a un número celular. Por favor, revisa e inténtalo de nuevo.'
          }
          this.frmMainErrors.pasoB = 'El número ingresado no corresponde a un número celular. Por favor, revisa e inténtalo de nuevo.'
          this.$refs.formVPhonex.setErrors(myObjectNoPhone);
          this.$forceUpdate()
          return false 
        }
      }
      return true
    },
    // ---------------
    // ---------------
    onSubmitVerifyA: async function ( isResendParam , skip) { // Verificacion paso 1
      this.$cannaDebug('-- verificaPhoneForm -- on SubmitVerifyA')
      if (skip === undefined ) {
        if (this.mainErrors > 0) {
          return false
        }
      }
      if (this.modalForm.processing === true){
        return false 
      }
      this.modalForm.processing = true
      //Se hace una segunda validacion por si las dudas en caso de que aprieten muy rapido el boton 
      var testTelphone = null 
      await this.pasoChange_validatePhone().then(responseTel => {
        testTelphone = responseTel
      })
      if (testTelphone===false){ 
        this.modalForm.processing = false
        return false 
      }
      // ------------ Continuamos 
      var test = 11
      var mpModel = this.getModelRequest('userVerifica')  // ${secure}://${server}/index.php/api/rum1/user/104/verify?notifier=phone
      var mdataModel = this.getModelRequest('userProfile')
      var reqRes = { action: [], profile: [], profileInfo:[] }
      var reqErr = { action: 0, profile: 0, profileInfo:0 }

      // // ------------------
      // alert("verifica phoneX")
      // Verificamos si el telefono hace match

      var profilePhone = this.frmDataOriginal.telefono.replace('+52')
      this.frmData.telefonoMasked  = this.getPhoneDataMasked(2, ('+52' + this.frmData.telefono)) 
      //------------------
      var mainPhoneChangedCheck = false 
      //------------------
      if (profilePhone !== this.frmData.telefono) {  // Si no son iguales los telefonos...! 

        mainPhoneChangedCheck = true
        /**
         * NOTA: AL realizar el patch al telefono principal, MANDA SMS Automaticamente...! 
         */
        var myProfilePasoB = this.createAxiosConfig ('patch', mdataModel, { 
          useData:false,
          data: { phone: '+52' + this.frmData.telefono }
        }, true, null)
        await this.doRequestByPromise(this.$apiV2, 'profilepersonales', reqRes.profile, myProfilePasoB).then((resx) => {
          // console.log ('Action -- Carga de Clientes -- GET ejecutado')
          var tmpresponseData = reqRes.profile[reqRes.profile.length - 1]
          if (tmpresponseData.type !== 'success') {
            reqErr.profile += 1
          }
        })
        if (reqErr.profile > 0) { // Si hay error de server, session o algo redireccionamos..!
          test = 44
          this.ex_handlerErrorFormRequest (reqRes.profile[0], reqErr.profile, this.$refs.formVPhonex, 'formVPhonex',  this.frmMainRefs.formVPhonex , 'formVPhonex')
          this.modalForm.processing = false
          this.$forceUpdate()
          return false 
        }
        // Paso bien el cambio de telefono principal 
        var myProfileConfig = this.createAxiosConfig ('get', mdataModel, {}, true, null)
        await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profileInfo, myProfileConfig).then((resx) => {
          // console.log ('Action -- Carga de Clientes -- GET ejecutado')
          var tmpresponseData = reqRes.profileInfo[reqRes.profileInfo.length - 1]
          if (tmpresponseData.type !== 'success') {
            reqErr.profileInfo += 1
          }
        })
        if (reqErr.profileInfo > 0) { // Si hay error de server, session o algo redireccionamos..!
          test = 44
          this.ex_handlerErrorFormRequest (reqRes.profileInfo[0], reqErr.profileInfo, this.$refs.formVPhonex, 'formVPhonex',  this.frmMainRefs.formVPhonex , 'formVPhonex')
          this.modalForm.processing = false
          this.$forceUpdate()
          return false 
        }
        // Actualizamos el STORE 
        var profileDataNew = reqRes.profileInfo[0].data.data
        this.$store.commit('myapp/setMainState',{ appUserProfile: profileDataNew })

        // Se actualiza la info del telefono en los props 
        this.frmData.telefonoMasked =  this.getPhoneDataMasked(2, ('+52' + this.frmData.telefono))
        this.frmDataOriginal.telefono = this.frmData.telefono
        this.frmDataOriginal.telefonoMasked =  this.getPhoneDataMasked(2, ( '+52' + this.frmData.telefono))

      }

      await this._waitRequestProc(500)

      // Se envia la verificacion de Telefono si es que no cambio el Numero ....!
      // -------------------------------------------        
      if (mainPhoneChangedCheck===false){

        var myPhoneVerifConfig = this.createAxiosConfig ('post', mpModel, { delimiters:[this.getProfileData.id], urlmixData:{ notifier:'phone'} }, false, null)
        // // ------------------
        await this.doRequestByPromise(this.$axios, 'verifPhone', reqRes.action, myPhoneVerifConfig).then((resx) => {
          // console.log ('Action -- verifica telefono Request -- POST ejecutado')
          var tmpresponseData = reqRes.action[reqRes.action.length - 1]
          if (tmpresponseData.type !== 'success') {
            reqErr.action += 1
          }
        })
        if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
          test = 55
          this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formVPhonex, 'formVPhonex',  this.frmMainRefs.formVPhonex , 'formVPhonex', false)
          this.modalForm.processing = false
          return false
        }

      }

      await this._waitRequestProc(200)
      // Todo bien no hubo errores 
      if (isResendParam===undefined || isResendParam===null || isResendParam===''){
        this.modalForm.stepForm = 0 

        this.pomodoro.canSendLink = false;
        this.pomodoro.timerCount = 60;
        this.pomodoroStart();
        this.$forceUpdate()
        setTimeout(() => {

          this.alertActionType = 0
          this.alertActionMsg = 'Se envío correctamente un mensaje de texto', 
          this.modalForm.processing = false
          this.alertActionShow = true

        }, 400)

      } else if ( isResendParam === true ) {

        // Start pomodoro data 
        this.pomodoro.canSendLink = false;
        this.pomodoro.timerCount = 60;
        this.pomodoroStart();

        this.$forceUpdate()
        setTimeout(() => {
          this.alertActionType = 0
          this.alertActionMsg = 'Se envío correctamente un mensaje de texto', 
          this.modalForm.processing = false
          this.alertActionShow = true
        }, 400)

      }
      return true
    },
    onSubmitVerifyCode: async function () { // Verificacion paso 2 
      this.$cannaDebug('-- verificaPhoneForm -- on onSubmitVerifyCode')
      if (this.mainErrorsVerifCode > 0) {
        return false
      }
      if (this.modalForm.processingSMS === true){ 
        return false
      }
      // ------------ Continuamos 
      var fullCodeSMS = this.frmData.verificaCodeData.join('') 
      if (fullCodeSMS.trim().length!==5){ 
        return false 
      }
      this.modalForm.processingSMS = true
      this.modalForm.processing = true
      // ------------ Continuamos 2
      var test = 11
      var mpModel = this.getModelRequest('userVerifica')  //${secure}://${server}/index.php/api/rum1/user/104/verify?notifier=phone&key=32975 
      var mdataModel = this.getModelRequest('userProfile')
      var reqRes = { action: [], profile: [], profileInfo:[] }
      var reqErr = { action: 0, profile: 0, profileInfo:0 }
      // -------------------------------------------
      // Se envia la verificacion de Telefono....!
      var myPhoneVerifConfig = this.createAxiosConfig ('get', mpModel, { 
        delimiters:[this.getProfileData.id], notifier:'phone', key: fullCodeSMS
      }, false, null)
      // var myPhoneVerifConfig = this.createAxiosConfig ('get', mpModel, { 
      //   delimiters:[this.getProfileData.id], 
      //   urlmixData:{ 
      //     notifier:'phone',
      //     key: fullCodeSMS
      //   } }, false, null)
      // // ------------------
      await this.doRequestByPromise(this.$axios, 'verifPhone', reqRes.action, myPhoneVerifConfig).then((resx) => {
        // console.log ('Action -- verifica telefono Request -- POST ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formVPhoneCoder, 'formVPhoneCoder',  this.frmMainRefs.formVPhoneCoder , 'formVPhoneCoder', false)
        this.modalForm.processingSMS = false
        this.modalForm.processing = false
        return false
      }
      await this._waitRequestProc(500)

      //recargamos los datos de la persona para el STORE VUEX 
      var myProfileConfig = this.createAxiosConfig ('get', mdataModel, {}, true, null)
      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profileInfo, myProfileConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.profileInfo[reqRes.profileInfo.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.profileInfo += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 44
        this.ex_handlerErrorFormRequest (reqRes.profileInfo[0], reqErr.profileInfo, this.$refs.formVPhonex, 'formVPhoneCoder',  this.frmMainRefs.formVPhonex , 'formVPhoneCoder')
        this.modalForm.processingSMS = true
        this.modalForm.processing = false
        this.$forceUpdate()
        return false 
      }
      // Actualizamos el STORE 
      var profileDataNew = reqRes.profileInfo[0].data.data
      this.$store.commit('myapp/setMainState',{ appUserProfile: profileDataNew })

      await this._waitRequestProc(1000)

      // Todo bien no hubo errores
      this.modalForm.stepForm = 2
      this.$forceUpdate()
      setTimeout(() => {
        this.modalForm.processingSMS = true
        this.modalForm.processing = false
      }, 400)
      return true
    },

    onSubmit: async function () { // WIP
      this.$cannaDebug('-- verificaPhoneForm -- onSubmit')
      if (this.mainErrors > 0) {
        return false
      }
      // ------------ Continuamos 
      var test = 11
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- verificaPhoneForm -- beforeCreate')
    this.initFormFlag = false
  },
  created () {
    this.$cannaDebug('-- verificaPhoneForm -- created')
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- verificaPhoneForm --  beforeMount')
  },
  mounted () {
    this.$cannaDebug('-- verificaPhoneForm -- mounted')
    //LLENAR los puestos Valido
    //this.initFormFlag = true
  },
  beforeUpdate () {
    // this.$cannaDebug('-- verificaPhoneForm -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- verificaPhoneForm -- updated')
  },
  activated () {
    // this.$cannaDebug('-- verificaPhoneForm -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- verificaPhoneForm -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- verificaPhoneForm -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- verificaPhoneForm -- destroyed')
  }
}
</script>
