<template>
  <div class="dash_mainpage" style="width: 100%;height: calc(100% - 0px);overflow: auto;">
    <div class="dash_page_contents" style="padding: 24px;">

      <div class="noselect">
        <div class="main_Title">
          ¡Hola, {{canna_NameUser}}!
        </div>
        <div class="main_subtitle" style="margin: 12px 0px 24px 0px;" 
          :class="{ 'main_subtitleAlt': ( is_Mobile === true || $q.screen.lt.sm ) ? true :false}"
          :style=" ( is_Mobile === true || $q.screen.lt.sm ) ? '':'padding: 0px !important;'"
        >
          <template v-if="canna_hasTrabID">
            <div style="font-weight: 500;margin-bottom: 12px;">
              Número de empleado: {{ canna_TrabId }}
            </div>
          </template>
          <div>
            La siguiente lista muestra las secciones que necesitas completar con tu información. 
          </div>
        </div>
      </div>

      <div class="dash_options_container">

        <!-- telefono -->
        <div class="row dash_optionItem">

          <div class="col-12 noselect action_item" style="display: flex;align-items: center;" @click.stop="expandeCollapseZone('phone')">
            <div>
                <cxIcon
                  iconType="cannav2_dash_phone"
                  size="40px"
                  class=""
                  :cls="''"
                  :overCls="''"
                  :state="0"
                  :hasMouseOver="false"
                  :hasAction="false"
                  :allowDisabled="false"
                  :allowClick="true"
                  style="margin-right: 10px;"
                  @iconClick="expandeCollapseZone('phone')"
                />
            </div>
            <div class="dash_optionItem_title" style="flex: 1;">
              <div>
                Verificación del número celular
              </div>
            </div>
            <div>
              <div class="action_item" style="width: 24px;height: 24px;display: flex;align-items: center;"
                @click.stop="expandeCollapseZone('phone')"
              >
                <template v-if="(zonas.phone===false)">
                  <q-icon name="expand_more" style="color: #6633FF; font-size: 1.4em;" />
                </template>
                <template v-else>
                  <q-icon name="expand_less" style="color: #6633FF; font-size: 1.4em;" />
                </template>
              </div>
            </div>
          </div>

          <template v-if="(zonas.phone===true)">
            <div class="col-12 dash_optionItem_container"
              :class="{
                'dash_optionItem_containerAlt': (zonasData.phone.init == false || (zonasData.phone.init == true && zonasData.phone.data.length<=0)) ? true : false 
              }"
            ><!-- zona expandida --> 
              <template v-if="(zonasAlert.phone.alertActionShow == true)">
                <div class="notifyZone notifyZoneVerifica noselect"
                  :class="(zonasAlert.phone.alertActionType!==0) ? 'notifyZoneRemove' : ''"
                >
                  <div style="flex: 1 1 0%; justify-content: center;padding: 0px 20px;display: flex;align-items: center;">
                      <q-icon name="check_circle" style="color:#ffffff; font-size: 22px; margin-right: 10px;" />
                      {{ zonasAlert.phone.alertActionMsg }}
                  </div>
                  <div style="margin-right: 14px;">
                      <div class="closenotifyZoneIcon action_item" @click.stop=" zonasAlert.phone.alertActionShow = false ">
                        <q-icon name="clear" style="color:#ffffff; font-size: 20px;" />
                      </div>
                  </div>
                </div>
              </template>

              <template v-if="(zonasData.phone.init == false || (zonasData.phone.init == true && zonasData.phone.data.length<=0))">
                <div class=""> 
                  <div class="noselect dash_optionItem_textExplainer">
                    Es importante que tomes un momento de tu tiempo para verificar tu número de teléfono celular.
                    Esto ayudará a confirmar tu identidad y mantener segura tu cuenta en Canna.
                  </div>
                  <div style="margin-top: 24px;">
                    <q-btn type="button" class="btnactionElementMain" color="app_mainBtn"
                      :label="'Verificar mi número celular'"
                    />
                  </div>
                </div>
              </template>
              <template v-else>

                <div class="">

                  <div> <!-- contenedor de telefonos --> 

                  <template v-for="(phoneItem, indexEmail) in zonasData.phone.data">
                    <div :id="'phoneItem_' + indexEmail + '_' + phoneItem.id" :key="'phoneItem_' + indexEmail + '_' + phoneItem.id"
                      class="dash_recordInfoItem"
                      :class="{
                        'dash_recordInfoItemAlt': ($q.screen.gt.xs && $q.screen.width<=790 && phoneItem.main===false) ? true: false,
                      }"
                    >
                      <div class="infoZone">
                        <div class="txtinfo_main">
                          {{phoneItem.phonestr}}
                        </div>

                        <template v-if="zonasData.phone.data.length>=1">
                          <div class="spacerRowTop8 txtinfo_sub">
                            <template v-if="(phoneItem.verified===false)">
                              <span class="action_item txt_porverificar"
                                @click.stop="action_verifyPhone(phoneItem)"
                              >Verificar número celular</span>
                            </template>
                            <template v-else>
                              <span class="txt_verificado">Verificado</span> <span style="margin-left: 10px;">{{phoneItem.verifiedStr}} </span>
                            </template>
                          </div>
                        </template>

                      </div>

                      <div class="btnZone" 
                        :class="{
                          'spacerRowTop24': ((is_Mobile===true || $q.screen.lt.sm) || ($q.screen.gt.xs && $q.screen.width<=790 && phoneItem.main===false))  ? true: false,
                          'btnZoneAlt':(phoneItem.main===false)
                        }"
                      >
                        <template v-if="(phoneItem.main===true)">
                          <q-btn unelevated  type="button" class="btnactionElement" color="app_mainBtn" :label="'Principal'" :disable="true"/>
                        </template> 
                        <template v-else>
                          <q-btn outline unelevated  type="button" class="btnactionElement" color="app_mainBtn" :label="'Eliminar'" style="width:130px;" />
                          <q-btn outline unelevated  type="button" class="btnactionElement" color="app_mainBtn" :label="'Elegir como principal'" 
                            :style=" (is_Mobile===true || $q.screen.lt.sm) ? '' : 'margin-left:16px;'" 
                            :class="{
                              'spacerRowTop24':(is_Mobile===true || $q.screen.lt.sm) ? true: false 
                            }"
                          />
                        </template>
                      </div>

                    </div>

                  </template>

                  </div>
                  <template v-if="1===22"> <!-- WIP a futuro -->
                    <div style="margin-top: 24px;">
                      <q-btn unelevated type="button" class="btnactionElementMain" color="app_mainBtn"
                        :label="'Agregar otro número celular'"
                      />
                    </div>
                  </template>

                </div>

              </template>
            </div>
          </template>

        </div>

        <!-- Email --> 
        <div class="row dash_optionItem">

          <div class="col-12 noselect action_item" style="display: flex;align-items: center;" @click.stop="expandeCollapseZone('email')">
            <div>
                <cxIcon
                  iconType="cannav2_dash_mail"
                  size="40px"
                  class=""
                  :cls="''"
                  :overCls="''"
                  :state="0"
                  :hasMouseOver="false"
                  :hasAction="false"
                  :allowDisabled="false"
                  :allowClick="true"
                  style="margin-right: 10px;"
                  @iconClick="expandeCollapseZone('email')"
                />
            </div>
            <div class="dash_optionItem_title" style="flex: 1;">
              <div>
                Verificación del correo electrónico
              </div>
            </div>
            <div>
              <div class="action_item" style="width: 24px;height: 24px;display: flex;align-items: center;"
                @click.stop="expandeCollapseZone('email')"
              >
                <template v-if="(zonas.email===false)">
                  <q-icon name="expand_more" style="color: #6633FF; font-size: 1.4em;" />
                </template>
                <template v-else>
                  <q-icon name="expand_less" style="color: #6633FF; font-size: 1.4em;" />
                </template>
              </div>
            </div>
          </div>

          <template v-if="(zonas.email===true)">
            <div class="col-12 dash_optionItem_container" 
              :class="{
                'dash_optionItem_containerAlt': (zonasData.phone.init == false || (zonasData.phone.init == true && zonasData.phone.data.length<=0)) ? true : false 
              }"
            ><!-- zona expandida --> 

              <template v-if="(zonasAlert.email.alertActionShow == true)">
                <div class="notifyZone notifyZoneVerifica noselect"
                  :class="(zonasAlert.email.alertActionType!==0) ? 'notifyZoneRemove' : ''"
                >
                  <div style="flex: 1 1 0%; justify-content: center;padding: 0px 20px;display: flex;align-items: center;">
                      <q-icon name="check_circle" style="color:#ffffff; font-size: 22px; margin-right: 10px;" />
                      {{ zonasAlert.email.alertActionMsg }}
                  </div>
                  <div style="margin-right: 14px;">
                      <div class="closenotifyZoneIcon action_item" @click.stop=" zonasAlert.email.alertActionShow = false ">
                        <q-icon name="clear" style="color:#ffffff; font-size: 20px;" />
                      </div>
                  </div>
                </div>
              </template>

              <template v-if="(zonasData.email.init == false || (zonasData.email.init == true && zonasData.email.data.length<=0))">
                <div class=""> 
                  <div class="noselect dash_optionItem_textExplainer">
                      Queremos asegurarnos de poder contactarte por cualquier medio, al validar tu dirección de correo electrónico podremos 
                      notificarte rápidamente de cualquier oportunidad de trabajo.
                  </div>
                  <div style="margin-top: 24px;">
                    <q-btn type="button" class="btnactionElementMain" color="app_mainBtn"
                      :label="'Verificar mi correo electrónico'"
                    />
                  </div>
                </div>
              </template>
              <template v-else>

                <div class="">

                  <div> <!-- contenedor de emails --> 

                  <template v-for="(emailItem, indexEmail) in zonasData.email.data">
                    <div :id="'emailItem_' + indexEmail + '_' + emailItem.id" :key="'emailItem_' + indexEmail + '_' + emailItem.id"
                      class="dash_recordInfoItem"
                      :class="{
                        'dash_recordInfoItemAlt': ($q.screen.gt.xs && $q.screen.width<=790 && emailItem.main===false) ? true: false,
                      }"
                    >
                      <div class="infoZone">
                        <div class="txtinfo_main">
                          {{emailItem.email}}
                        </div>

                        <template v-if="zonasData.email.data.length>=1">
                          <div class="spacerRowTop8 txtinfo_sub">
                            <template v-if="(emailItem.verified===false)">
                              <span class="action_item txt_porverificar" 
                                @click.stop="action_verifyEmail(emailItem)"
                              >
                                Verificar correo electrónico
                              </span>
                            </template>
                            <template v-else>
                              <span class="txt_verificado">Verificado</span> <span style="margin-left: 10px;">{{emailItem.verifiedStr}}</span>
                            </template>
                          </div>
                        </template>

                      </div>
                      <div class="btnZone" 
                        :class="{
                          'spacerRowTop24': ((is_Mobile===true || $q.screen.lt.sm) || ($q.screen.gt.xs && $q.screen.width<=790 && emailItem.main===false))  ? true: false,
                          'btnZoneAlt':(emailItem.main===false)
                        }"
                      >
                        <template v-if="(emailItem.main===true)">
                          <q-btn unelevated  type="button" class="btnactionElement" color="app_mainBtn" :label="'Principal'" :disable="true"/>
                        </template> 
                        <template v-else>
                          <q-btn outline unelevated  type="button" class="btnactionElement" color="app_mainBtn" :label="'Eliminar'" style="width:130px;" />
                          <q-btn outline unelevated  type="button" class="btnactionElement" color="app_mainBtn" :label="'Elegir como principal'"  
                            :style=" (is_Mobile===true || $q.screen.lt.sm) ? '' : 'margin-left:16px;'" 
                            :class="{
                              'spacerRowTop24':(is_Mobile===true || $q.screen.lt.sm) ? true: false 
                            }"
                          />
                        </template>
                      </div>
                    </div>

                  </template>

                  </div>

                  <template v-if="1===22"> <!-- WIP a futuro -->
                    <div style="margin-top: 24px;">
                      <q-btn unelevated type="button" class="btnactionElementMain" color="app_mainBtn"
                        :label="'Agregar otro correo electrónico'"
                      />
                    </div>
                  </template>

                </div>

              </template>

            </div>
          </template>

        </div>

        <!-- Info personal --> 
        <div class="row dash_optionItem">

          <div class="col-12 noselect action_item" style="display: flex;align-items: center;" @click.stop="expandeCollapseZone('personal')">
            <div>
                <cxIcon
                  iconType="cannav2_dash_personal"
                  size="40px"
                  class=""
                  :cls="''"
                  :overCls="''"
                  :state="0"
                  :hasMouseOver="false"
                  :hasAction="false"
                  :allowDisabled="false"
                  :allowClick="true"
                  style="margin-right: 10px;"
                  @iconClick="expandeCollapseZone('personal')"
                />
            </div>
            <div class="dash_optionItem_title" style="flex: 1;">
              <div>
                Información personal
              </div>
            </div>
            <div>
              <div class="action_item" style="width: 24px;height: 24px;display: flex;align-items: center;"
                @click.stop="expandeCollapseZone('personal')"
              >
                <template v-if="(zonas.personal===false)">
                  <q-icon name="expand_more" style="color: #6633FF; font-size: 1.4em;" />
                </template>
                <template v-else>
                  <q-icon name="expand_less" style="color: #6633FF; font-size: 1.4em;" />
                </template>
              </div>
            </div>
          </div>

          <template v-if="(zonas.personal===true)">
            <div class="col-12 dash_optionItem_containerAlt"><!-- zona expandida --> 

              <div class=""> 
                <div class="noselect dash_optionItem_textExplainer">
                    ¡Vas muy bien! Pero aún hay datos pendientes por responder acerca de 
                    tu información personal. 
                    Recuerda que al completar está sección nos ayudas a poder encontrar el 
                    perfil de trabajo ideal para ti.
                </div>
                <div style="margin-top: 24px;">
                  <q-btn unelevated type="button" class="btnactionElementMain" color="app_mainBtn"
                    :label="'Continuar con mi información'" @click.stop="gotoAPP_Page(0)"
                  />
                </div>
              </div>

            </div>
          </template>

        </div>

        <!-- Documentos --> 
        <div class="row dash_optionItem">

          <div class="col-12 noselect action_item" style="display: flex;align-items: center;" @click.stop="expandeCollapseZone('docs')">
            <div>
                <cxIcon
                  iconType="cannav2_dash_docs"
                  size="40px"
                  class=""
                  :cls="''"
                  :overCls="''"
                  :state="0"
                  :hasMouseOver="false"
                  :hasAction="false"
                  :allowDisabled="false"
                  :allowClick="true"
                  style="margin-right: 10px;"
                  @iconClick="expandeCollapseZone('docs')"
                />
            </div>
            <div class="dash_optionItem_title" style="flex: 1;">
              <div>
                Documentación
              </div>
            </div>
            <div>
              <div class="action_item" style="width: 24px;height: 24px;display: flex;align-items: center;"
                @click.stop="expandeCollapseZone('docs')"
              >
                <template v-if="(zonas.docs===false)">
                  <q-icon name="expand_more" style="color: #6633FF; font-size: 1.4em;" />
                </template>
                <template v-else>
                  <q-icon name="expand_less" style="color: #6633FF; font-size: 1.4em;" />
                </template>
              </div>
            </div>
          </div>

          <template v-if="(zonas.docs===true)">
            <div class="col-12 dash_optionItem_containerAlt"><!-- zona expandida --> 

              <div class=""> 
                <div class="noselect dash_optionItem_textExplainer">
                    Necesitamos algunos de tus documentos oficiales para corroborar que todo está 
                    en orden, revisa qué documentos son los que necesitas subir.
                </div>
                <div style="margin-top: 24px;">
                  <q-btn unelevated type="button" class="btnactionElementMain" color="app_mainBtn"
                    :label="'Ver documentos'" @click.stop="gotoAPP_Page(1)"
                  />
                </div>
              </div>

            </div>
          </template>

        </div>

      </div>

    </div>

    <!-- Zona de formularios -->
    <!-- Verifica telefono -->
    <frmVerificaPhone
      ref="frmVphone"
      :actionForm="phoneFormAction"
      :phoneRecord="phoneRecordUse"
      v-on:doCloseDialog="doCloseVPhonexForm"
      v-on:phoneVerified="doVPhoneVerified"
    >
    </frmVerificaPhone>
    <frmVerificaEmail
      ref="frmVEmail"
      :actionForm="emailFormAction"
      :emailRecord="emailRecordUse"
      v-on:doCloseDialog="doCloseVEmailxForm"
    >
    </frmVerificaEmail>

    <!-- Loaders --> 
    <q-inner-loading :showing="loading_Process" class="app_innerLoader" style="top: 0px; z-index: 9000;position: fixed;">
      <q-spinner-orbit size="100px" color="indigo" />
    </q-inner-loading>

    <q-inner-loading :showing="zloadingData" style="top: 0px; z-index: 9000;position: fixed;">
      <q-spinner-gears :size="(pageSize === 'sm' || pageSize === 'xs') ? '90px' : '120px' " color="cannaLoading" />
    </q-inner-loading>



  </div>
</template>

<script>
import { requestPack } from './../../../boot/axios' // APP code 

import logOut_Mixin from './../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../mixins/helperBase.js'


export default {
  name: 'dashboard_screen',
  mixins:[
    helperBase_Mixin, 
    logOut_Mixin,
    myplatform_Mixin
  ],
  components:{
    cxIcon: require('components/appIcons.vue').default,
    frmVerificaPhone: require('components/forms/cuenta/verificaTelephone.vue').default,
    frmVerificaEmail: require('components/forms/cuenta/verificaEmail.vue').default
  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      initCmp:false,
      zinitScreen: false,
      zloadingData: false,
      processingData: false,
      loading_Process: false, 
      //---------------
      frm_verifPhoneShow: false,
      phoneFormAction: 'mainchange', // verify, add, remove , mainchange
      phoneRecordUse: null,
      //---------------
      emailFormAction: 'mainchange', // verify, add, remove , mainchange
      emailRecordUse: null,
      zonas: {
        phone: false,
        email: false,
        personal: false,
        docs: false
      }, 
      zonasAlert: {
        phone: {
          alertActionShow:false, 
          alertActionType:0,
          alertActionMsg:'Se eliminó correctamente un número celular', 
          // Se eliminó correctamente un número celular 
          // Se agregó correctamente un número celular
          // Se cambió correctamente el número celular principal
        },
        email: {
          alertActionShow:false, 
          alertActionType:0,
          alertActionMsg:'Se cambió correctamente el correo electrónico principal',
          // Se cambió correctamente el correo electrónico principal
          // Se eliminó correctamente un correo electrónico
        }
      },
      zonasData: {
        phone:{ init:true, data:[] },
        email:{ init:true, data:[] }
      }
    }
  },
  computed: {
    canna_NameUser () { 
      return this.$store.state.myapp.appUserProfile.alias
    },
    canna_TrabId () { 
      return this.$store.state.myapp.appUserProfile.trab_id
    },
    canna_hasTrabID () { 
      var tmpId = this.$store.state.myapp.appUserProfile.trab_id
      return (tmpId == undefined || tmpId===null ||  tmpId==='' ) ? false: true 
    },
    layoutParent () { 
      return this.$parent.$parent.$parent.$parent
    }
  },
  watch: {
    'zonasAlert.phone.alertActionShow' : function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) { 
        setTimeout(() => {
          this.zonasAlert.phone.alertActionShow = false
        }, 7000)
      }
    },
    'zonasAlert.email.alertActionShow' : function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) { 
        setTimeout(() => {
          this.zonasAlert.email.alertActionShow = false
        }, 7000)
      }
    }
  },
  methods: {
    ...requestPack,
    test () {
      this.$cannaDebug('-- dashMainScreen -- test')
    },
    expandeCollapseZone (zonaName) { 
      if (this.zonas.hasOwnProperty('' + zonaName)) { 
        this.zonas[zonaName] = !this.zonas[zonaName]
      }
    },
    gotoAPP_Page ( pageIndex ){
      var myPages = [
        '/dashboard/personal',
        '/dashboard/docs'
      ]
      if (pageIndex===0){
        this.layoutParent.goto_PersonalPage()
      } else { 
        var newRuta = myPages[pageIndex]
        if (newRuta!== undefined) { 
          setTimeout(() => {
            this.$router.push(newRuta)
          }, 150)
        }
      }
    },
    action_verifyPhone (phoneItem){ 
      this.phoneFormAction = 'verify'
      this.phoneRecordUse = JSON.parse(JSON.stringify(phoneItem))
      this.$forceUpdate()
      setTimeout(() => {
        this.$refs.frmVphone.doShow()
      }, 200)
    },
    async doCloseVPhonexForm (payload) {
      this.emaphoneFormActionilFormAction = 'verify'
      this.phoneRecordUse = null
      this.$refs.frmVphone.doHide()
      this.loading_Process = true 
      this.$refs.frmVEmail.doHide()
      await this.initPhoneNMailData().then ( respx => {
        this.$cannaDebug('-- dashMainScreen -- phone n email data init -- activated -- finish')
      })
      this.loading_Process = false
      this.$forceUpdate()
      return true 
    },
    async doVPhoneVerified (payload){
      this.$cannaDebug('-- dashMainScreen -- do V Phone Verified')
      this.$forceUpdate()
      //payload = this.$emit('phoneVerified', { newState: false, finishVerifica:true })
      if (payload.hasOwnProperty('finishVerifica')){ 
        if (payload.finishVerifica === true ){ 
          this.loading_Process = true 
          await this.initPhoneNMailData().then ( respx => {
            this.$cannaDebug('-- dashMainScreen -- phone n email data init -- activated -- finish')
          })
          this.zonasAlert.phone.alertActionMsg = 'Se verificó el número de celular.'
          this.zonasAlert.phone.alertActionShow = true
          this.loading_Process = false
          this.$forceUpdate()
        }
      }
      return true 
    },
    action_verifyEmail ( emailItem ) {
      this.emailFormAction = 'verify'
      this.emailRecordUse = JSON.parse(JSON.stringify(emailItem))
      this.$forceUpdate()
      setTimeout(() => {
        this.$refs.frmVEmail.doShow()
      }, 200)
    },
    async doCloseVEmailxForm (payload) {
      // this.$emit('doCloseDialog', { newState: false, finish:true })  SI terminaron el proceso 
      if (payload.hasOwnProperty('finish')){ 
        if (payload.finish === true ){ 
          this.loading_Process = true 
          this.$refs.frmVEmail.doHide()
          await this.initPhoneNMailData().then ( respx => {
            this.$cannaDebug('-- dashMainScreen -- phone n email data init -- activated -- finish')
          })
          this.loading_Process = false
        }
      } else {
        // Por si cambiaron el Email (se actualiza la info)
        this.loading_Process = true 
        this.$refs.frmVEmail.doHide()
        await this.initPhoneNMailData().then ( respx => {
          this.$cannaDebug('-- dashMainScreen -- phone n email data init -- activated -- finish (2)')
        })
        this.loading_Process = false
        this.emailFormAction = 'verify'
        this.emailRecordUse = null
      }
      this.$forceUpdate()
    },
    //-------------------
    getProfileDataMasked (tipoDato , valor ) {
      var myTel = valor
      if (tipoDato === 1 && valor.length===13) {  // +XX (XX) XXXX XX90
        var tel = myTel.split('')
        tel.splice(3, 0, ' (');
        tel.splice(6, 0, ') ');
        tel.splice(11, 0, ' ');
        var prueba = tel.join('')
        return '' + prueba
      } else if (tipoDato === 2 && valor.length===13) {  // +XX (XX) XXXX XX90
        var telb = myTel.split('')
        telb.splice(3, 0, ' (XX) XXXX XX');
        telb.splice(4, 8);
        var pruebaB = telb.join('')
        return '' + pruebaB
      } else {
        return '-- Error en telefono --'
      }
    },
    getDT_hacecuanto_Str ( fechaStr ){
      var test = 11
      var myFechaHOy = this.$moment() 
      var dtxTest = this.$moment(fechaStr, 'YYYY-MM-DD HH:mm:ss')
      var minutos = myFechaHOy.diff(dtxTest, 'minutes')
      var horas = myFechaHOy.diff(dtxTest, 'hours')
      var dias = myFechaHOy.diff(dtxTest, 'days') 
      var semanas = myFechaHOy.diff(dtxTest, 'weeks')
      var meses = myFechaHOy.diff(dtxTest, 'months')
      var yearsX = myFechaHOy.diff(dtxTest, 'years')
      test = 22
      var mystrDtx = ''
      if (minutos > 60 ){ // Revisamos HORAS 
        if (horas > 24 ){ // Revisamos dias 
          if (dias>7){ // Revisamos semanas 
            if (semanas >= 4 && meses !==0 && meses >0) { //Revisamos meses 
              if (meses > 12){ 
                mystrDtx = 'Hace ' +  yearsX + ' año' + ((yearsX==1) ? '' : 's')
              } else { 
                mystrDtx = 'Hace ' +  meses + ' mes' + ((meses==1) ? '' : 'es')
              }
            } else {
              mystrDtx = 'Hace ' +  semanas + ' semana' + ((semanas==1) ? '' : 's')
            }
          } else { 
            mystrDtx = 'Hace ' +  dias + ' dia' + ((dias==1) ? '' : 's')
          }
        } else { 
          mystrDtx = 'Hace ' +  horas + ' hora' + ((horas==1) ? '' : 's')
        }
      } else { 
        mystrDtx = 'Hace ' +  minutos + ' minuto' + ((minutos==1) ? '' : 's')
      }
      return mystrDtx
    },
    //-------------------
    async initPhoneNMailData () { 
      // Inicializa los datos de Telefono y de Email para mostrarlos 
      var dataPhones = []
      var dataMails = []
      // Creamos el 1er registro de telefono (de profile) 

      var phoneDtStr = ''
      if ( 
        this.getProfileData.phone_verified === null || this.getProfileData.phone_verified === 0 || 
        this.getProfileData.phone_verified === undefined || this.getProfileData.phone_verified === ''){
        phoneDtStr = '' 
      } else { 
        phoneDtStr = this.getDT_hacecuanto_Str(this.getProfileData.phone_verified)
      }

      dataPhones.push({
        id:0, 
        _rcType: 'profile',
        _rcId: '' + this.getProfileData.id,
        phone: '' + this.getProfileData.phone ,
        phonestr: this.getProfileDataMasked(2, this.getProfileData.phone) ,
        main: true,
        verified: (this.getProfileData.phone_verified === null || this.getProfileData.phone_verified === 0 || this.getProfileData.phone_verified === undefined || this.getProfileData.phone_verified === '') ? false : true,
        verifiedDate : (this.getProfileData.phone_verified === null || this.getProfileData.phone_verified === 0 || this.getProfileData.phone_verified === undefined) ? '' : '' + this.getProfileData.phone_verified ,// provicional
        verifiedStr: '' + phoneDtStr
      })
      // -----------
      // Obtenemos los Telefonos Adicionales 
      // pendiente (WIP  a futuro)
      // ---------

      // Creamos el 1er registro de email (de profile) 
        var emailDtStr = ''
        if ( 
          this.getProfileData.email_verified === null || this.getProfileData.email_verified === 0 || 
          this.getProfileData.email_verified === undefined || this.getProfileData.email_verified === ''){
          emailDtStr = '' 
        } else { 
          emailDtStr = this.getDT_hacecuanto_Str(this.getProfileData.email_verified)
        }

        dataMails.push({
        id:0, 
        _rcType: 'profile',
        _rcId: '' + this.getProfileData.id,
        email: '' + this.getProfileData.email,
        main: true,
        verified: (this.getProfileData.email_verified === null || this.getProfileData.email_verified === 0 || this.getProfileData.email_verified === undefined || this.getProfileData.email_verified === '') ? false : true,
        verifiedDate : (this.getProfileData.email_verified === null || this.getProfileData.email_verified === 0 || this.getProfileData.email_verified === undefined || this.getProfileData.email_verified === '') ? '' : '' + this.getProfileData.phone_verified ,// provicional
        verifiedStr: '' + emailDtStr
      })
      // -----------
      // Obtenemos los Emails Adicionales 
      // pendiente (WIP  a futuro)
      // ---------

      // Terminamos .... 
      this.zonasData.phone.init = true
      this.zonasData.phone.data = dataPhones
      this.zonasData.email.init = true
      this.zonasData.email.data = dataMails
      // --------------
      return true 
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- dashMainScreen -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- dashMainScreen -- created')
    this.initCmp = false
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- dashMainScreen --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- dashMainScreen -- mounted')
    await this.initPhoneNMailData().then ( respx => {
      this.$cannaDebug('-- dashMainScreen -- phone n email data init -- mounted -- finish')
    })
    this.zloadingData = false
    this.initCmp = true
    this.$forceUpdate()
  },
  beforeUpdate () {
    // this.$cannaDebug('-- dashMainScreen -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- dashMainScreen -- updated')
  },
  async activated () {
    this.$cannaDebug('-- dashMainScreen -- activated')
    if (this.initCmp === true) {
      this.zloadingData = true 
      await this.initPhoneNMailData().then ( respx => {
        this.$cannaDebug('-- dashMainScreen -- phone n email data init -- activated -- finish')
      })
      this.zloadingData = false
      this.$forceUpdate()
    }
  },
  deactivated () {
    // this.$cannaDebug('-- dashMainScreen -- deactivated')
    this.zloadingData = false
  },
  beforeDestroy () {
    // this.$cannaDebug('-- dashMainScreen -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- dashMainScreen -- destroyed')
  }
}
</script>
